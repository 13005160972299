const BasketShortSummary = {
  jqAjaxRequest: function(isTrigger) {
    $.ajax({
      url: "/koszyk/index.html",
      method: "get",
      data: {
        ajax: 'return_basket_summary_json'
        //return_basket_summary_json: "yes"
      },
      success: function(json) {
        if(json.data) {
          let result = json.data;
          console.log(isTrigger);
          if (!isTrigger) {
            $("#loading").removeClass("loading_active");
          }

          let allProductsQuantity = result.liczba_biletow + result.liczba_towarow + result.liczba_voucherow;

          if (allProductsQuantity > 0) {
            //$("#block-basket").show();
            $("#clock").countdown({until: "'+1800s'"});
            $(".block-basket__clock").addClass("block-basket__clock_active");
            //$('header').addClass('header-offset');
          } else {
            //$("#block-basket").hide();
            $("#clock").countdown("destroy");
            $(".block-basket__clock").removeClass("block-basket__clock_active");
            //$('header').removeClass('header-offset');
          }

          $(".block-basket__produkty-ilosc").html(allProductsQuantity);
          $(".block-basket__cena-wartosc").html(result.do_zaplaty + "<i> "+result.waluta+"</i>");
          BasketShortSummary.setBiletyTotal();
          //$("#js-booking-form-summary-quantity").text(allProductsQuantity);
          //$("#js-booking-form-summary-value").text(result.do_zaplaty);
          //$(".bilety-odmiana").html(result.bilety_odmiana);
        }
      },
      error: function(xhr, status, error) {
        console.error(status);
        console.error(error);
      }
    });
  },

  setBiletyTotal: function() {
    let formRezerwacja = $("form#rezerwacja");
    let formVouchery = $("form#wybor_voucherow");
    if (formRezerwacja.length > 0) {
      let url = formRezerwacja.attr("action") + "&totals=true";
      $.ajax({
        url: url,
        method: "post"
      }).done(function(result) {
        $("#js-booking-form-summary-value").text(result.bilety_cena_razem);
        $("#js-booking-form-summary-quantity").text(result.bilety_ilosc_razem);
      });
    }

    if (formVouchery.length > 0) {
      $.ajax({
        url: "/bony/vouchery-serie.html?bony_razem_json=true",
        method: "post",
        success: function(result) {
          $("#js-booking-form-summary-value").text(result.bony_razem);
          $("#js-booking-form-summary-quantity").text(result.bony_ilosc);

        }

      });
    }
  },

  init: function(isTrigger) {
    this.jqAjaxRequest(isTrigger);
  }
};

export default BasketShortSummary;