// Custom styles
import './scss/styles.scss';

// Custom scripts
import PreventBodyScrolling from './js/prevent-body-scrolling';
import PaymentsCarousel from './js/payments-carousel';
import EventsAccordion from './js/events-accordion';
import Calendar from './js/calendar';
import Tooltips from './js/tooltips';
import MessageModal from './js/message-modal.js';
import BasketFullSummary from './js/basket-full-summary';
import SlickSlider from './js/utils/slick-slider';
import LanguageSelector from './js/language-selector';

(() => {
    if (typeof $ === 'undefined') {
        throw new TypeError('... requires jQuery.');
    }

    $(document).ready(() => {
        const preventBodyScrolling = new PreventBodyScrolling();
        const paymentsCarousel = new PaymentsCarousel($('#carousel-payments'));
        const eventsAccordion = new EventsAccordion();
        const calendar = new Calendar();
        const tooltips = new Tooltips();
        const messageModal = new MessageModal();
        const basketFullSummary = new BasketFullSummary();
        const langSelector = new LanguageSelector();
        const storeSlider = new SlickSlider([
            {
                selector: $('#slider'),
                settings: {
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 2000,
                    dots: true,
                    infinite: true,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    speed: 500,
                    prevArrow:
                        '<button type="button" class="slider__arrow slider__arrow_prev"></button>',
                    nextArrow:
                        '<button type="button" class="slider__arrow slider__arrow_next"></button>',
                    dotsClass: 'slider__dots',
                    responsive: [
                        {
                            breakpoint: 1030,
                            settings: {
                                arrows: false
                            }
                        }
                    ]
                }
            }
        ]);

        const storeProductSlider = new SlickSlider([
            {
                selector: $('#slider-product'),
                settings: {
                    arrows: false,
                    asNavFor: '#slider-product-thumbnails',
                    draggable: false,
                    fade: true,
                    lazyLoad: 'ondemand',
                    slidesToScroll: 1,
                    slidesToShow: 1
                }
            },
            {
                selector: $('#slider-product-thumbnails'),
                settings: {
                    asNavFor: '#slider-product',
                    dots: false,
                    focusOnSelect: true,
                    slidesToScroll: 1,
                    slidesToShow: 4,
                    prevArrow:
                        '<button type="button" class="slider-product__arrow slider-product__arrow_prev"></button>',
                    nextArrow:
                        '<button type="button" class="slider-product__arrow slider-product__arrow_next"></button>',
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2
                            }
                        }
                    ]
                }
            }
        ]);

        preventBodyScrolling.addEvents();
        paymentsCarousel.init();
        eventsAccordion.addEvents();
        calendar.init();
        tooltips.init();
        messageModal.init();
        basketFullSummary.init();
        storeSlider.init();
        storeProductSlider.init();
        langSelector.init();
    });
})();

// Images
const requireContext = require.context(
    './images',
    true,
    /\.(png|svg|jpg|jpeg|gif)$/i
);

requireContext.keys().map(requireContext);
