class EventsAccordion {
  constructor() {
    this.element = $("#events-accordion > .events__group > .collapse");
  }

  addEvents() {
    const that = this;

    this.element.on("shown.bs.collapse", function() {
      that.addClass($(this));
    });

    this.element.on("hidden.bs.collapse", function() {
      that.removeClass($(this));
    });
  }

  addClass(el) {
    el.parent().addClass("events__group_active");
  }

  removeClass(el) {
    el.parent().removeClass("events__group_active");
  }
}

export default EventsAccordion;
