class MessageModal {
    constructor() {
        this.messageModal = $("#messageModal");
    }

    init() {
        if (
            this.messageModal.length > 0 &&
            this.messageModal.find(".modal-body") &&
            this.messageModal.find(".modal-body").html() !== ""
        ) {
            $(".message-trigger-button").click();
        }
    }

    pokazDialog(tytul, tresc) {
        $("#messageModalLabel").html(tytul);
        $(".modal-body").html(tresc);
        $(".message-trigger-button").click();
    }
}

export default MessageModal;
