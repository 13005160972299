class PaymentsCarousel {
  constructor(selector) {
    this.window = $(window);
    this.carousel = selector;
    this.carouselItems = this.carousel.find(">ul");
    this.carouselItem = this.carouselItems.find(">li");
    this.carouselItemCalcWidth = null;
    this.interval = null;
    this.timeoutValue = 2000;
    this.mql = [
      {
        isActive: false,
        media: "(min-width: 0px) and (max-width: 575px)",
        minWidth: 0,
        amountOfVisibleIcon: 0
      },
      {
        isActive: false,
        media: "(min-width: 576px) and (max-width: 767px)",
        minWidth: 576,
        amountOfVisibleIcon: 0
      },
      {
        isActive: false,
        media: "(min-width: 768px) and (max-width: 1029px)",
        minWidth: 768,
        amountOfVisibleIcon: 0
      },
      {
        isActive: true,
        media: "(min-width: 1030px) and (max-width: 1365px)",
        minWidth: 1030,
        amountOfVisibleIcon: 7
      },
      {
        isActive: true,
        media: "(min-width: 1366px) and (max-width: 1919px)",
        minWidth: 1366,
        amountOfVisibleIcon: 8
      },
      {
        isActive: true,
        media: "(min-width: 1920px)",
        minWidth: 1920,
        amountOfVisibleIcon: 8
      }
    ];
  }

  events() {
    const that = this;

    this.window.on("resize", function() {
      that.checkMediaQueryBreakpoint();
    });

    this.carousel.hover(
      function() {
        that.stop();
      },
      function() {
        that.start();
      }
    );
  }

  checkMediaQueryBreakpoint() {
    const mql = this.mql;

    for (let i = 0; i < mql.length; i++) {
      let isActive = mql[i].isActive;
      let media = mql[i].media;
      let amountOfVisibleIcon = mql[i].amountOfVisibleIcon;
      let mediaMatches = window.matchMedia(media).matches;

      if (mediaMatches) {
        if (isActive) {
          this.setIconsWidth(amountOfVisibleIcon);
          this.start();
        } else {
          this.stop();
          break;
        }
      }
    }
  }

  setIconsWidth(amount) {
    let carouselWidth = this.carousel.outerWidth();
    let carouselItemWidth = carouselWidth / amount;

    this.carouselItemCalcWidth = carouselItemWidth;
    this.carouselItem.css({
      "flex-basis": carouselItemWidth + "px"
    });
  }

  start() {
    // console.log("start");
    const that = this;

    clearInterval(this.interval);
    this.interval = setInterval(function() {
      that.move();
    }, this.timeoutValue);
  }

  stop() {
    // console.log("stop");
    clearInterval(this.interval);
  }

  move() {
    this.carouselItems
      .animate(
        {
          left: -this.carouselItemCalcWidth + "px"
        },
        this.timeoutValue / 2,
        "swing",
        function() {
          let firstCarouselItem = $(this).find(":eq(0)");

          $(this).append(firstCarouselItem);
          $(this).css({
            left: 0
          });
        }
      )
      .clearQueue();
  }

  init() {
    this.events();
    this.checkMediaQueryBreakpoint();
  }
}

export default PaymentsCarousel;
