class Calendar {
  constructor() {
    this.element = $(".js-calendar-month-day-selector_disabled");
  }

  addEvents() {
    this.element.on("click", function(event) {
      event.preventDefault();
    });
  }

  init() {
    this.addEvents();
  }
}

export default Calendar;
