import BasketShortSummary from "./utils/basket-short-summary";

class BasketFullSummary {
    constructor() {
        this.body = $("body");
        this.toggleButton = $(".js-block-basket-link");
        this.blockBasket = $("#block-basket");
        this.blockBasketSummary = $(".basket-full-summary");
        this.blockBasketSummaryContent = $(".basket-full-summary__content");
        this.blockBasketSummaryEmpty = $(".basket-full-summary__empty");
        this.blockBasketSummaryTickets = $(".basket-full-summary__tickets");
        this.blockBasketSummaryProducts = $(".basket-full-summary__products");
        this.blockBasketSummaryVouchers = $(".basket-full-summary__vouchers");
        this.blockBasketSummaryRemove = ".basket-full-summary__remove";
        this.blockBasketSummaryValue = $(".basket-full-summary__value");
        this.loading = $("#loading");
        this.animateDuration = 600;
        this.url = "/koszyk/podsumowanie.html";
        this.urlParam = {
            json: true
        };
        this.isTrigger = true;
    }

    events() {
        const that = this;

        this.toggleButton.on("click", function (event) {
            event.preventDefault();

            that.addPreloader();
            that.toggleSummary();
        });

        $(document).on("click", this.blockBasketSummaryRemove, function (event) {
            const isPageName = that.getPageName("rezerwacja-koszyk-index");

            if (!isPageName) {
                event.preventDefault();

                that.addPreloader();
                that.removeSummaryData(event.target);
            }
        });
    }

    getPageName(pageName) {
        return this.body.hasClass(pageName);
    }

    toggleSummary() {
        this.blockBasketSummary.toggleClass("basket-full-summary_active");
        this.blockBasket.toggleClass("block-basket_active");
        this.loading.toggleClass("loading_active loading_overlay");

        if (this.blockBasket.hasClass("block-basket_active")) {
            if (this.blockBasket.hasClass("block-basket_active")) {
                this.scrollToToggleSummary();
            }

            // Aktualizacja produktów w koszyku (blok widoczny po kliknięciu w przycisk koszyka)
            this.getSummaryData(this.url, "GET", this.urlParam);

        } else {
            this.blockBasketSummaryEmpty.addClass("d-none");
            this.blockBasketSummaryContent.addClass("d-none");
        }
    }

    addPreloader() {
        this.blockBasketSummary.find(".preloader").addClass("preloader_active");
    }

    removePreloader() {
        this.blockBasketSummary.find(".preloader").removeClass("preloader_active");
    }

    scrollToToggleSummary() {
        const that = this;

        $("html, body").animate(
            {
                scrollTop: 0
            },
            that.animateDuration,
            function () {
                $(this).clearQueue();
            }
        );
    }

    getSummaryData(url, method, data) {
        this.jqAjaxRequest(url, method, data);
    }

    removeSummaryData(target) {
        let url = target.dataset.removeUrl;

        this.jqAjaxRequest(url, "POST");
    }

    jqAjaxRequest(url, method, data) {
        const that = this;

        $.ajax({
            url: url,
            method: method,
            data: data
        }).done(function (data) {
            that.removePreloader();
            if (method === "POST") {
                // Aktualizacja produktów w koszyku (blok widoczny po kliknięciu w przełącznik koszyka)
                that.getSummaryData(that.url, "GET", that.urlParam);

                // Aktualizacja ilości produktów na przełączniku koszyka.
                if (that.isTrigger) {
                    BasketShortSummary.init(that.isTrigger);
                }
            } else {
                that.createSummaryDataContent(data);
            }
        }).fail(function (jqXHR, textStatus) {
            console.log(jqXHR);
            console.log("Status: " + textStatus);
        });
    };

    createSummaryDataContent(data) {
        // console.log(data);
        let skladoweKoszyka = [];

        if (data.liczbaBiletow > 0) {
            skladoweKoszyka.push("bilety");
        }

        if (data.liczbaTowarow > 0) {
            skladoweKoszyka.push("towary");
        }

        if (data.liczbaVoucherow > 0) {
            skladoweKoszyka.push("vouchery");
        }

        let blockBasketSummaryTicketsHTML = "";

        if (skladoweKoszyka.length > 1) {
            blockBasketSummaryTicketsHTML += "<h3 class=\"basket-full-summary__content-title\">Bilety</h3>";
        }

        if (data.liczbaBiletow > 0) {
            this.blockBasketSummaryContent.removeClass("d-none");

            $.each(data.terminy, function (terminIndex, terminRow) {
                blockBasketSummaryTicketsHTML += "<p class='text-left text-primary mb-1'>" + terminRow.wydarzenieNazwa + " " + terminRow.dataGodzina + "</p>";
                blockBasketSummaryTicketsHTML += "<div class=\"table-responsive\"><table class=\"basket-full-summary__table w-100 mb-3\">";
                blockBasketSummaryTicketsHTML += "<thead><tr><th>" + commonTranslates.rodzaj_biletu + "</th><th>" + commonTranslates.liczba + "</th><th>" + commonTranslates.wartosc + "</th><th>" + commonTranslates.usun + "</th></tr></thead><tbody>";

                $.each(terminRow.grupyMiejsc, function (grupaIndex, grupaRow) {
                    $.each(grupaRow.bilety, function (biletIndex, biletRow) {
                        let ticket = biletRow;

                        blockBasketSummaryTicketsHTML += "<tr>";
                        blockBasketSummaryTicketsHTML += "<td>" + ticket.nazwaPozycji + "</td>";
                        blockBasketSummaryTicketsHTML += "<td>" + ticket.ilosc + "</td>";
                        blockBasketSummaryTicketsHTML += "<td>" + ticket.wartosc + "</td>";
                        blockBasketSummaryTicketsHTML += "<td><a class=\"basket-full-summary__remove\" href=\"" + ticket.usunUrl + "\" data-remove-url=\"" + ticket.usunUrl + "\"></a></td>";
                        blockBasketSummaryTicketsHTML += "</tr>";
                    });
                });
                blockBasketSummaryTicketsHTML += "</tbody></table></div>";
            });
        }

        // Produkty
        const products = data.towary;
        let blockBasketSummaryProductsHTML = "";

        if (data.liczbaTowarow > 0) {
            this.blockBasketSummaryContent.removeClass("d-none");

            if (skladoweKoszyka.length > 1) {
                blockBasketSummaryProductsHTML += "<h3 class=\"basket-full-summary__content-title\">Towary</h3>";
            }

            blockBasketSummaryProductsHTML += "<div class=\"table-responsive\"><table class=\"basket-full-summary__table w-100 mb-3\">";
            blockBasketSummaryProductsHTML += "<thead><tr><th>Towar</th><th>Liczba</th><th>Wartość</th><th>Usuń</th></tr></thead><tbody>";


            $.each(products, function (index, product) {
                blockBasketSummaryProductsHTML += "<tr>";
                blockBasketSummaryProductsHTML += "<td>" + product.nazwa + "</td>";
                blockBasketSummaryProductsHTML += "<td>" + product.ilosc + "</td>";
                blockBasketSummaryProductsHTML += "<td>" + product.wartosc + "</td>";
                blockBasketSummaryProductsHTML += "<td><a class=\"basket-full-summary__remove\" href=\"" + product.usunUrl + "\" data-remove-url=\"" + product.usunUrl + "\"></a></td>";
                blockBasketSummaryProductsHTML += "</tr>";
            });
        }

        blockBasketSummaryProductsHTML += "</tbody></table></div>";

        //Vouchery:
        let blockBasketSummaryVouchersHTML = "";
        if (data.liczbaVoucherow > 0) {
            //blockBasketSummaryVouchers
            this.blockBasketSummaryContent.removeClass("d-none");

            if (skladoweKoszyka.length > 1) {
                blockBasketSummaryProductsHTML += "<h3 class=\"basket-full-summary__content-title\">Vouchery</h3>";
            }

            blockBasketSummaryVouchersHTML += "<div class=\"table-responsive\"><table class=\"basket-full-summary__table w-100 mb-3\">";
            blockBasketSummaryVouchersHTML += "<thead><tr><th>Nazwa</th><th>Liczba</th><th>Wartość</th><th>Usuń</th></tr></thead><tbody>";

            $.each(data.vouchery, function (index, voucher) {
                blockBasketSummaryVouchersHTML += "<tr>";
                blockBasketSummaryVouchersHTML += "<td>" + voucher.nazwa + "</td>";
                blockBasketSummaryVouchersHTML += "<td>" + voucher.ilosc + "</td>";
                blockBasketSummaryVouchersHTML += "<td>" + voucher.wartosc + "</td>";
                blockBasketSummaryVouchersHTML += "<td><a class=\"basket-full-summary__remove\" href=\"" + voucher.usunUrl + "\" data-remove-url=\"" + voucher.usunUrl + "\"></a></td>";
                blockBasketSummaryVouchersHTML += "</tr>";
            });

            blockBasketSummaryVouchersHTML += "</tbody></table></div>";
        }

        if (
            parseInt(data.liczbaBiletow) === 0 &&
            parseInt(data.liczbaTowarow) === 0 &&
            parseInt(data.liczbaVoucherow) === 0
        ) {
            this.blockBasketSummaryTickets.html("");
            this.blockBasketSummaryContent.addClass("d-none");
            this.blockBasketSummaryEmpty.removeClass("d-none");
        } else {
            this.blockBasketSummaryTickets.html(blockBasketSummaryTicketsHTML);
            this.blockBasketSummaryProducts.html(blockBasketSummaryProductsHTML);
            this.blockBasketSummaryVouchers.html(blockBasketSummaryVouchersHTML);
            this.blockBasketSummaryValue.text(data.wartoscZamowienia);
        }
    }

    init() {
        this.events();
    }
}

export default BasketFullSummary;