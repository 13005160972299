/**
 * SlickSlider
 */
class SlickSlider {
  constructor(settings) {
    this.settings = settings;
  }

  checkParameter(parameter) {
    const isArray = Array.isArray(parameter);

    if (!isArray) {
      throw "Parameter nie jest tablicą!";
    }

    if (parameter.length === 0 || parameter.length > 2) {
      throw "Tablica powinna zawierać od 1 do 2 obiektów z ustawieniami slider'a!";
    }

    this.initSlick();
  }

  initSlick() {
    let settingsLength = this.settings.length;

    for (let i = 0; i < settingsLength; i++) {
      let element = this.settings[i];
      let selector = element.selector;
      let settings = element.settings;

      selector.slick(settings);
    }
  }

  init() {
    try {
      this.checkParameter(this.settings);
    } catch (e) {
      console.error(e);
    }
  }
}

export default SlickSlider;