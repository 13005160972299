class LanguageSelector {
    init() {
        $('.language-selector__link').hover(
            function() {
                if ($(window).width() < 1366) {
                    return;
                }
                $('.languages-to-select').show();
            },
            function() {
                if ($('.languages-to-select').is(':hover') === false) {
                    $('.languages-to-select').hide();
                }
            }
        );

        $('.languages-to-select').hover(
            function() {
                console.log('hover');
            },
            function() {
                console.log('hover out');
                $('.languages-to-select').hide();
            }
        );
    }
}

export default LanguageSelector;
