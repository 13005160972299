class PreventBodyScrolling {
  constructor() {
    this.body = $("body");
    this.element = $("#navbarNavDropdown");
  }
  
  addEvents() {
    const that = this;

    this.element.on("shown.bs.collapse", function() {
      that.addClass();
    });

    this.element.on("hidden.bs.collapse", function() {
      that.removeClass();
    });
  }

  addClass() {
    this.body.addClass("menu-visible");
  }

  removeClass() {
    this.body.removeClass("menu-visible");
  }
}

export default PreventBodyScrolling;
